import { GlobalContext } from "context";
import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "ui-atoms";

const useApiCall = (service: any, flag: boolean = false) => {
  const { dispatch } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (param: any) => {
    setLoading(true);
    try {
      const result = await service(param);
      setLoading(false);

      if (result?.status) {
        setData(result?.data || result?.result);
        return result?.data || result?.result;
      } else {
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message || error?.detail);
      // if (error?.response?.status === HTTP_ERROR_UNAUTHORIZED) {
      //   navigate(`/`);
      //   return;
      // }

      // if (error?.response?.status === HTTP_ERROR_WRITE_ACCESS) {
      //   dispatch({
      //     type: SET_PERMISSION_MODAL,
      //     payload: {
      //       open: true,
      //     },
      //   });
      //   return;
      // }
      // if (error?.response?.status === HTTP_ERROR_BAD_REQUEST) {
      //   dispatch({
      //     type: SET_ERROR_MODAL,
      //     payload: {
      //       open: true,
      //       content: {
      //         code: error?.response?.status,
      //         values: error?.response?.data?.errors?.[0] || error?.response?.data,
      //       },
      //     },
      //   });
      //   return;
      // }
      if (
        !flag &&
        (error?.message || error?.detail?.message || error?.detail)
      ) {
        Toast.error(error?.message || error?.detail?.message || error?.detail);
      }
    }
  }, []);
  return [fetchData as Function, loading, data, error];
};

export default useApiCall;
