import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const getCountryAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/country/?${param_str}`);
  return res;
};

export const postCountryAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/country/`, data);
  return res;
};

export const patchCountryAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/country/${pk}/`, data);
  return res;
};

export const deleteCountryAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/country/${pk}/`);
  return res;
};



export const getStateAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/state/?${param_str}`);
  return res;
};

export const postStateAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/state/`, data);
  return res;
};

export const patchStateAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/state/${pk}/`, data);
  return res;
};

export const deleteStateAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/state/${pk}/`);
  return res;
};



export const getCityAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/city/?${param_str}`);
  return res;
};

export const postCityAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/city/`, data);
  return res;
};

export const patchCityAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/city/${pk}/`, data);
  return res;
};

export const deleteCityAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/city/${pk}/`);
  return res;
};



export const getMarketAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/market/?${param_str}`);
  return res;
};

export const postMarketAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/market/`, data);
  return res;
};

export const patchMarketAPI = async (data: any) => {
  const pk = data?.pk;
  delete data?.pk;
  const res = await axios.patch(`${API_PREFIX_URL}/market/${pk}/`, data);
  return res;
};

export const deleteMarketAPI = async (pk: number) => {
  const res = await axios.delete(`${API_PREFIX_URL}/market/${pk}/`);
  return res;
};
