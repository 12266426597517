import { Route, Routes, useLocation } from "react-router-dom";
import { URLS } from "constant";
import { LoginCallback } from "@okta/okta-react";
import { useContext, useEffect } from "react";
import { GlobalContext } from "context";
import { RequiredAuth } from "./RequiredAuth";
import {
  Homepage,
  Profile,
  Metadata,
  AuthHome,
  LocationDetail,
  NoPage,
  OfficeHome,
  Userpage,
} from "pages";

const RootRoutes = () => {
  const { state } = useContext(GlobalContext);
  const { hideTooltip } = state;

  const location = useLocation();

  useEffect(() => {
    if (hideTooltip) hideTooltip();
  }, [location]);

  return (
    <Routes>
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="/" element={<RequiredAuth />}>
        <Route path={`/`} element={<AuthHome />} />
        <Route path={`/${URLS.PROFILE.ROOT}`} element={<Homepage />} />
        <Route path={`/${URLS.PROFILE.DETAIL}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.FORM}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.LOG}`} element={<Profile />} />

        <Route path={`/${URLS.METADATA.ROOT}`} element={<Metadata />} />

        <Route path={`/${URLS.LOCATION.ROOT}`} element={<OfficeHome />} />
        <Route path={`/${URLS.LOCATION.DETAIL}`} element={<LocationDetail />} />

        <Route path={`/${URLS.USER.ROOT}`} element={<Userpage />} />
      </Route>
      {/* <Route path={`/${URLS.ERROR}`} element={<StatusError />} /> */}
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default RootRoutes;
