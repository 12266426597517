import { LazyLoadImage } from "react-lazy-load-image-component";
import { TopBar } from "ui-molecules";
import { LayoutBase } from "ui-organisms";
import SolrImg from "assets/images/solor.svg";
import { SolBadge, SolButton, SolIcon } from "@solstice/sol-react";
import cn from "classnames";
import "./index.css";
import { useNavigate } from "react-router-dom";

interface ManagementItemProps {
  label: string;
  description: string;
  notifyNum: number | string;
  link: string;
  className?: string;
}

const TOOLS = [
  {
    label: "Broker Profile Management",
    description:
      "Add and update content for broker's profiles. ",
    notifyNum: 4,
    link: "/profile",
  },
  {
    label: "Metadata Management",
    description: "Manage services, industries, markets, and more. ",
    notifyNum: 4,
    link: "/metadata",
  },
  {
    label: "JLL Office Location Management",
    description:
      "Manage the metadata associated to JLL Office locations. ",
    notifyNum: 4,
    link: "/location",
  },
  {
    label: "Country/City Management",
    description:
      "Manage metadata related to Countries, States, and Cities.",
    notifyNum: 4,
    link: "/country",
  },
  {
    label: "User management",
    description:
      "Update roles for users using this system",
    notifyNum: 4,
    link: "/user",
  },
];

const ManagementItem: React.FC<ManagementItemProps> = ({
  label,
  description,
  notifyNum,
  link,
  className,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "p-6 flex rounded-lg bg-jll-surface-base-secondary-subdued flex-col justify-between",
        className
      )}
    >
      <p className="text-xl mb-6">{label}</p>
      <p className="text-sm mb-10">{description}</p>
      <div className="flex flex-row w-full items-center justify-end">
        {/* <SolBadge variant="filled">
          <span>{notifyNum} Notifications</span>
        </SolBadge> */}
        <SolButton
          variant="tertiary"
          className="go-btn"
          onSol-click={() => navigate(link)}
        >
          <SolIcon icon="arrow_right_alt" />
        </SolButton>
      </div>
    </div>
  );
};

const AuthHome = () => {
  return (
    <LayoutBase>
      <TopBar />
      <div className="absolute top-[80px] left-0 right-0 bottom-0 overflow-y-auto">
        <div className="flex flex-col w-full bg-jll-surface-base-default justify-center items-center pt-[10vh] pb-[15vh] relative overflow-hidden">
          <p className="text-[32px] text-white mb-2">
            Welcome to JLL Broker Profile Management System!
          </p>
          <p className="text-xl text-jll-text-base-subdued-3">
            See your data management options below.
          </p>
          <LazyLoadImage
            src={SolrImg}
            className="absolute left-[65%] top-[10%] bottom-0 z-0"
          />
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-1 lg:grid-cols-4 p-10 gap-10">
          {TOOLS.map(({ label, description, notifyNum, link }, idx) => (
            <ManagementItem
              label={label}
              description={description}
              notifyNum={notifyNum}
              link={link}
              key={idx}
            />
          ))}
        </div>
      </div>
    </LayoutBase>
  );
};

export default AuthHome;
