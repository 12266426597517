import { LayoutAuthHome } from "ui-organisms";
import Leftbar from "./Leftbar";
import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { Input, SelectAutoComplete, Toast } from "ui-atoms";
import { SearchOutlined } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import debounce from "lodash.debounce";
import { getDecimalFormating, getFormatedDate } from "utils";
import {
  AddTagModal,
  EditTagModal,
  Loading,
  StyledUserValue,
  Table,
} from "ui-molecules";
import {
  METADATA_TABLE_COLUMNS,
  SEARCH_RESULT_LIMIT,
  SET_ALERT_MODAL,
  STATUS_ACTIVE,
  USER_TABLE_COLUMNS,
} from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useApiCall } from "hooks";
import {
  deleteCityAPI,
  deleteCountryAPI,
  deleteIndustryAPI,
  deleteMarketAPI,
  deleteServiceAPI,
  deleteStateAPI,
  getUserAPI,
} from "services";
import { GlobalContext } from "context";
import {
  DirectionEnum,
  FacetsProps,
  ManageUserProps,
  OptionProps,
} from "types";

const Userpage = () => {
  const { dispatch, state } = useContext(GlobalContext);
  const { meta } = state;
  const location = useLocation();
  const navigate = useNavigate();
  const [getUser] = useApiCall(getUserAPI);
  const [data, setData] = useState<any[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [facets, setFacets] = useState<FacetsProps>();

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    loadData();
  }, [paramFilters]);

  const loadData = () => {
    setIsFirstLoading(true);
    getUser({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
        setSortFields(res?.sort_fields);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoading(true);
    getUser({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.docs]);
        setPage(res?.page);
        setTotal(res?.total);
        setSortFields(res?.sort_fields);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  // const handleDeleteTag = (item: any) => {
  //   const action = () => {
  //     try {
  //       API(item?.pk).then((res: any) => {
  //         loadData();
  //         Toast.success(`Successfully removed "${item?.name}"`);
  //       });
  //     } catch (err) {}
  //   };

  //   dispatch({
  //     type: SET_ALERT_MODAL,
  //     payload: {
  //       open: true,
  //       kind: "error",
  //       title: `Are you sure to remove "${item?.name}"?`,
  //       description: `Currently ${item?.count} user's are associated with this Industry`,
  //       icon: "warning",
  //       btn1: {
  //         label: "Cancel",
  //         onClick: () => {
  //           dispatch({
  //             type: SET_ALERT_MODAL,
  //             payload: {
  //               open: false,
  //               kind: "",
  //               title: "",
  //               description: "",
  //               icon: "",
  //               btn1: null,
  //               btn2: null,
  //             },
  //           });
  //         },
  //       },
  //       btn2: {
  //         label: "Confirm",
  //         onClick: () => {
  //           dispatch({
  //             type: SET_ALERT_MODAL,
  //             payload: {
  //               open: false,
  //               kind: "",
  //               title: "",
  //               description: "",
  //               icon: "",
  //               btn1: null,
  //               btn2: null,
  //             },
  //           });
  //           action();
  //         },
  //       },
  //     },
  //   });
  // };

  const onChangeRole = (user: ManageUserProps, groups: OptionProps[]) => {
    // const userGroups = user?.group || [];
    // const updateGroups = [...groups]?.map((x) => x.value) || [];
    // const deleteGroups =
    //   [...userGroups]?.filter((x) => {
    //     return !updateGroups.includes(x);
    //   }) || [];
    // updateGroups?.forEach((group) => {
    //   postUserGroup({
    //     user: user.pk,
    //     group,
    //   })
    // });
    // deleteGroups?.forEach((group) => {
    //   deleteUserGroup({
    //     user: user.pk,
    //     group,
    //   });
    // });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  return (
    <>
      <LayoutAuthHome>
        <Leftbar />
        <div className="w-full h-full relative">
          {/* Filter */}
          <div className="flex flex-row items-center py-6 mx-10 border-b border-b-jll-stroke-subdued justify-end">
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[350px]"
                placeholder="Search by name, country, city..."
              />
            </div>
          </div>

          <div className="flex flex-col mx-10 relative">
            <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
              <span>{`${getDecimalFormating(total)} result${
                total > 1 ? "s" : ""
              }`}</span>
            </div>
          </div>

          <div className="absolute top-[143px] left-0 right-0 bottom-0 px-10 overflow-y-auto pt-4">
            {isFirstLoading ? (
              <Table.Loading avatar={false} />
            ) : (
              <>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {USER_TABLE_COLUMNS?.map((column, idx) => (
                        <Table.Th
                          key={idx}
                          filterId={column.id}
                          sortFields={sortFields}
                          filters={paramFilters}
                          onClick={onClickSorting}
                        >
                          {column?.label}
                        </Table.Th>
                      ))}
                      <Table.Th>Role</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((item: any, idx: number) => (
                      <Table.Tr key={idx}>
                        {USER_TABLE_COLUMNS?.map((column, idx1) => (
                          <Table.Td key={idx1} className="!whitespace-normal">
                            <StyledUserValue user={item} valueKey={column.id} />
                          </Table.Td>
                        ))}
                        <Table.Td className="space-x-3">
                          <SelectAutoComplete
                            name="role"
                            className="!mb-0"
                            isMulti
                            options={[...meta?.user_group]?.map((x: any) => ({
                              value: x?.id,
                              label: x?.name,
                            }))}
                            placeholder="Select Role"
                            // onChange={(option) => onChangeRole(user, option)}
                            // value={user?.group?.map(
                            //   (groupId: number, index) => ({
                            //     value: groupId,
                            //     label: user?.group_name?.[index] || "",
                            //   })
                            // )}
                          />
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
                {!!(total > data?.length) && (
                  <div
                    className="w-full py-2 flex justify-center items-center"
                    ref={sentryRef}
                  >
                    <Loading />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutAuthHome>
    </>
  );
};

export default Userpage;
