import { ArrowBackOutlined } from "@material-ui/icons";
import {
  SolButton,
  SolDropdown,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getCityAPI,
  getCountryAPI,
  getMarketAPI,
  getStateAPI,
  patchProfileAPI,
} from "services";
import { ProfileProps } from "types";
import { SelectAutoComplete } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import { getOptions } from "utils";
import * as Yup from "yup";

interface GeographyProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
}

const INITIAL_VALUES = {
  country: undefined,
  language: undefined,
  state: undefined,
  city: undefined,
  markets: [],
};

const validationSchema = Yup.object().shape({
  country: Yup.object().required("This field is required"),
  language: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
});

const Geography: React.FC<GeographyProps> = ({
  profile,
  setProfile,
  isLoading,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getCountry] = useApiCall(getCountryAPI);
  const [getState] = useApiCall(getStateAPI);
  const [getCity] = useApiCall(getCityAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "country") {
        if (profile?.country)
          formValues = {
            ...formValues,
            [key]: {
              label: profile?.country?.name,
              value: profile?.country?.id,
            },
          };
        return;
      }
      if (key === "state") {
        if (!!profile?.cities?.length)
          formValues = {
            ...formValues,
            [key]: {
              label: profile?.cities?.[0]?.state,
              value: profile?.cities?.[0]?.state,
            },
          };
        return;
      }
      if (key === "city") {
        if (!!profile?.cities?.length)
          formValues = {
            ...formValues,
            [key]: {
              label: profile?.cities?.[0]?.state,
              value: profile?.cities?.[0]?.id,
            },
          };
        return;
      }
      if (key === "markets") {
        let options: any = [];
        if (!!profile?.markets?.length) {
          options = profile?.markets?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        formValues = {
          ...formValues,
          [key]: options,
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] ? (profile as any)?.[key] : null,
      };
    });

    setValues({
      ...formValues,
    });
  }, [profile]);

  const { setFieldValue, values, setValues, touched, errors, handleBlur } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  const fetchState = useMemo(() => {
    return async () => {
      if (!values?.country) return [];
      const response = await getState({
        country: [(values?.country as any)?.value],
        limit: 500,
      });
      return response?.docs;
    };
  }, [values?.country]);

  const fetchCity = useMemo(() => {
    return async () => {
      if (!values?.state) return [];
      const response = await getCity({
        state: [(values?.state as any)?.value],
        limit: 500,
      });
      return response?.docs;
    };
  }, [values?.state]);

  useEffect(() => {
    fetchState().then((result) =>
      setStateOptions(
        result?.map((item: any) => ({ value: item?.pk, label: item?.name }))
      )
    );
  }, [fetchState]);

  useEffect(() => {
    fetchCity().then((result) =>
      setCityOptions(
        result?.map((item: any) => ({ value: item?.pk, label: item?.name }))
      )
    );
  }, [fetchCity]);

  const loadCountryOptions = async (keyword: string) => {
    return Promise.all([
      profile?.country && getCountry({ ids: [profile?.country?.id] || [] }),
      getCountry({ keyword }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
    });
  };

  const loadMarketOptions = async (keyword: string) => {
    return Promise.all([
      !!profile?.markets?.length &&
        getMarket({ ids: [profile?.markets?.[0]?.id] || [] }),
      getMarket({ keyword }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((market: any) => {
        return {
          value: market.pk,
          label: market?.name,
        };
      });
    });
  };

  const handleUpdate = (
    key: "country" | "state" | "city" | "language" | "markets"
  ) => {
    try {
      let value;
      switch (key) {
        case "country":
        case "state":
          value = (values?.[key] as any)?.value;
          break;

        case "markets":
          value = (values?.[key] || [])?.map((item: any) => item?.value);
          break;

        default:
          value = values?.[key];
      }

      patchProfile({
        pk: profile?.id,
        [key]: value,
      }).then((res: any) => {
        setProfile({
          ...profile,
          [key]: res?.[key],
        });
      });
    } catch (err) {}
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Geography</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
          </div>
          <form>
            <div className="w-full flex flex-row space-x-10 mb-10">
              <SelectAutoComplete
                name="language"
                label="Preferred Language"
                required
                className="w-full !mb-0"
                size="sm"
                options={getOptions("language", meta)}
                value={getOptions("language", meta)?.find(
                  (item: any) => item?.value === values?.language
                )}
                onChange={(e) => {
                  setFieldValue("language", e?.value);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("language");
                }}
                error={
                  touched.language ? (errors.language as string) : undefined
                }
              />
              <div className="w-full" />
              <div className="w-full" />
            </div>
            <div className="w-full flex flex-row space-x-10 mb-10">
              <SelectAutoComplete
                label="Country"
                required
                className="w-full !mb-0"
                size="sm"
                loadOptions={loadCountryOptions}
                value={values?.country}
                name="country"
                onChange={(e) => {
                  setFieldValue("country", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("country");
                }}
                error={touched.country ? (errors.country as string) : undefined}
              />
              <SelectAutoComplete
                label="Role"
                className="w-full !mb-0"
                size="sm"
                name="markets"
                loadOptions={loadMarketOptions}
                isMulti
                value={values?.markets}
                onChange={(e) => {
                  setFieldValue("markets", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("markets");
                }}
                error={touched.markets ? (errors.markets as string) : undefined}
              />
              <div className="w-full" />
            </div>
            <div className="w-full flex flex-row space-x-10 mb-10">
              <SelectAutoComplete
                name="city"
                label="City"
                className="w-full !mb-0"
                size="sm"
                options={cityOptions}
                isDisabled={!cityOptions?.length}
                value={cityOptions?.find(
                  (item: any) => item?.value === values?.city
                )}
                onChange={(e) => {
                  setFieldValue("city", e?.value);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("city");
                }}
                error={touched.city ? (errors.city as string) : undefined}
              />
              <SelectAutoComplete
                name="state"
                label="State"
                required
                className="w-full"
                size="sm"
                options={stateOptions}
                value={values?.state}
                onChange={(e) => {
                  setFieldValue("state", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                  handleUpdate("state");
                }}
                error={touched.state ? (errors.state as string) : undefined}
              />
              <div className="w-full" />
              {/* <SolTextField label="Postal Code" className="w-full" size="small" /> */}
            </div>
          </form>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "license");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Geography;
