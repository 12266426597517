// Convert decimal format (1000 => 1,000, 2023001 => 2,023,001)
export const getDecimalFormating = (
  num: number | undefined | string,
  isDecimal = false
) => {
  if (!num || isNaN(Number(num))) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: isDecimal ? 2 : 0,
  }).format(Number(num));
};
