import axios from "axios";
import { API_CLOUDINARY_PREFIX_URL } from "constant";
import { Toast } from "ui-atoms";
const sha1 = require("sha1");

export const postImageAPI = async (file: any) => {
  if (file?.size > 25000000) {
    Toast.warn(
      `${file?.name || ""} is over 25 MB. Please choose a smaller file`
    );
    return;
  }

  const formData = new FormData();
  formData.append("file", file);
  const upload_preset = process.env.REACT_APP_API_CLOUDINARY_UPLOAD_PRESET;
  formData.append("upload_preset", upload_preset || "");
  formData.append(
    "api_key",
    process.env.REACT_APP_API_CLOUDINARY_API_KEY || ""
  );

  const tiStmp = Math.ceil(new Date().getTime() / 1000).toString();
  formData.append("timestamp", tiStmp);
  let myData = `timestamp=${tiStmp}&upload_preset=${upload_preset}${process.env.REACT_APP_CLOUDINARY_SECRET}`;
  const sign = sha1(myData);
  formData.append("signature", sign);

  const instance = axios.create();
  delete instance.defaults.headers.common["Authorization"];
  delete instance.defaults.headers.common["X-CSRFToken"];

  const res = await instance
    .post(`${API_CLOUDINARY_PREFIX_URL}/image/upload`, formData, {
      withCredentials: false,
    })
    .then((response) => {
      return response.data["secure_url"];
    });

  return res;
};
