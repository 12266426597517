import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "300px",
};

interface MapProps {
  placeId: string;
}

const MapWithPlaceID: React.FC<MapProps> = ({ placeId }) => {
  const [center, setCenter] = useState({ lat: 37.7749, lng: -122.4194 }); // Default to San Francisco
  const [selectedPlace, setSelectedPlace] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const mapRef = useRef<google.maps.Map | null>(null);

  const onLoad = useCallback(
    (mapInstance: any) => {
      mapRef.current = mapInstance;

      const request = {
        placeId: placeId,
        fields: ["geometry", "name"],
      };

      const service = new google.maps.places.PlacesService(mapInstance);

      service.getDetails(request, (place, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          place?.geometry
        ) {
          const location: any = place?.geometry?.location;
          setCenter({ lat: location.lat(), lng: location.lng() });
          setSelectedPlace({ lat: location.lat(), lng: location.lng() });
        }
      });
    },
    [placeId]
  );

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={14}
      onLoad={onLoad}
    >
      {selectedPlace && <Marker position={selectedPlace} />}
    </GoogleMap>
  );
};

export default MapWithPlaceID;
