import { LayoutDetail } from "ui-organisms";
import DetailLeftbar from "./DetailLeftbar";
import {
  SolBadge,
  SolButton,
  SolIcon,
  SolTextField,
} from "@solstice/sol-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropzone, Switch } from "ui-atoms";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SampleImg from "assets/images/homepage_bg.jpg";
import { usePlacesWidget } from "react-google-autocomplete";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useRef, useState } from "react";
import MapWithPlaceID from "./GoogleMap";
import { ArrowBackOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const INITIAL_VALUES = {
  name: "",
  number: "",
  address: "",

  city: "",
  state: "",
  country: "",
  postal_code: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  number: Yup.string().required("This field is required"),
  address: Yup.string().required("This field is required"),
});

const center = {
  lat: -3.745, // Default center point latitude
  lng: -38.523, // Default center point longitude
};

const LocationDetail = () => {
  const navigate = useNavigate();
  const [placeId, setPlaceId] = useState(null);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place: any) => {
      let address = "";
      place?.address_components?.forEach((component: any) => {
        const types = component.types;

        if (types.includes("street_number")) {
          address += `${component.long_name} `;
        }
        if (types.includes("route")) {
          address += component.long_name;
        }
        if (types.includes("locality")) {
          setFieldValue("city", component.short_name);
        }
        if (types.includes("administrative_area_level_1")) {
          setFieldValue("state", component.short_name);
        }
        if (types.includes("country")) {
          setFieldValue("country", component.long_name);
        }
        if (types.includes("postal_code")) {
          setFieldValue("postal_code", component.long_name);
        }
      });
      setFieldValue("address", address);
      setPlaceId(place?.place_id);
    },
  });

  const { handleBlur, setFieldValue, values, errors, touched, setValues } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  return (
    <>
      <LayoutDetail>
        {/* <DetailLeftbar /> */}
        <div className="w-full h-full relative flex flex-col overflow-y-auto bg-white">
          <div className="min-h-[68px] flex flex-row items-center border-b border-b-jll-light-stroke-default justify-between px-10">
            <button
              onClick={() => navigate("/location")}
              className="flex flex-row items-center"
            >
              <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
              Back
            </button>
            <SolButton variant="secondary">
              Publish <SolIcon icon="public" />
            </SolButton>
          </div>
          <div className="w-full px-10 py-8">
            <section>
              <div className="mb-12">
                <p className="text-xl mb-3">Office location details</p>
                <p className="text-sm text-jll-text-base-subdued">
                  Curabitur a ipsum eget dolor malesuada tristique
                </p>
              </div>
              <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                <SolTextField
                  label="Office name"
                  required
                  value={values.name}
                  onSol-input={(e: any) => setFieldValue("name", e?.detail)}
                  id="name"
                  placeholder="Enter JLL office name"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.name ? errors?.name : ""}
                />
                <SolTextField
                  label="Phone number"
                  required
                  value={values.number}
                  onSol-input={(e: any) => setFieldValue("number", e?.detail)}
                  id="number"
                  placeholder="Enter JLL office phone number"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.number ? errors?.number : ""}
                />
              </div>

              <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                <SolTextField
                  label="Office Address"
                  required
                  ref={ref as any}
                  value={values.address}
                  onSol-input={(e: any) => setFieldValue("address", e?.detail)}
                  id="address"
                  placeholder="Enter JLL office address"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.name ? errors?.name : ""}
                />
                <div className="flex flex-row justify-between items-center pt-6">
                  <div>
                    <p>Google autocomplete</p>
                    <p className="text-jll-text-base-subdued">
                      Alternatively use google autocomplete
                    </p>
                  </div>
                  <Switch
                    enabled={false}
                    onChange={(flag: any) => {
                      // setIsRepublish(flag);
                    }}
                    className="!mb-0"
                  />
                </div>
              </div>

              <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                <SolTextField
                  label="City"
                  required
                  value={values.city}
                  onSol-input={(e: any) => setFieldValue("city", e?.detail)}
                  id="city"
                  placeholder="Enter City"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.city ? errors?.city : ""}
                />
                <SolTextField
                  label="State"
                  required
                  value={values.state}
                  onSol-input={(e: any) => setFieldValue("state", e?.detail)}
                  id="state"
                  placeholder="Enter State"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.state ? errors?.state : ""}
                />
              </div>

              <div className="flex grid md:grid-cols-3 grid-cols-2 gap-10 mb-10">
                <SolTextField
                  label="Country"
                  required
                  value={values.country}
                  onSol-input={(e: any) => setFieldValue("country", e?.detail)}
                  id="country"
                  placeholder="Enter Country"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.country ? errors?.country : ""}
                />
                <SolTextField
                  label="Postal Code"
                  required
                  value={values.postal_code}
                  onSol-input={(e: any) =>
                    setFieldValue("postal_code", e?.detail)
                  }
                  id="postal_code"
                  placeholder="Enter Postal Code"
                  onBlur={(e) => {
                    handleBlur(e);
                    // handleUpdate("first_name", values.name);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // handleUpdate("first_name", values.name);
                    }
                  }}
                  size="small"
                  errorLabel={touched?.postal_code ? errors?.postal_code : ""}
                />
              </div>
            </section>

            <section className="mb-12">
              <div className="mb-12">
                <p className="text-xl mb-3">Location images</p>
                <p className="text-sm text-jll-text-base-subdued">
                  Curabitur a ipsum eget dolor malesuada tristique
                </p>
              </div>
              <Dropzone className="mb-12" />

              <div className="grid md:grid-cols-4 grid-cols-3">
                <div>
                  <LazyLoadImage
                    src={SampleImg}
                    className="object-cover w-full h-[180px] mb-6 rounded-md"
                  />
                  <SolBadge
                    size="small"
                    state="success"
                    variant="filled"
                    icon="check"
                    className="[& .solBadge__content]:!rounded mb-3"
                  >
                    <span>Use as primary</span>
                  </SolBadge>
                  <div className="mb-3">
                    <p>Image142134123_1121.jpg</p>
                    <p className="text-jll-text-base-subdued">
                      15 July 2024, 14:30
                    </p>
                  </div>
                  <SolButton variant="outlined" size="small">
                    Delete <SolIcon icon="delete" />
                  </SolButton>
                </div>
              </div>
            </section>

            <section>
              <div className="mb-12">
                <p className="text-xl mb-3">Map</p>
                <p className="text-sm text-jll-text-base-subdued">
                  Curabitur a ipsum eget dolor malesuada tristique
                </p>
              </div>

              <div>{placeId && <MapWithPlaceID placeId={placeId} />}</div>
            </section>
          </div>
        </div>
      </LayoutDetail>
    </>
  );
};

export default LocationDetail;
