import { LazyLoadImage } from "react-lazy-load-image-component";
import { SolButton, SolIcon } from "@solstice/sol-react";
import LinkedinIcon from "assets/icons/linkedin.svg";
import { BrokerProps, ProfileProps } from "types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "ui-molecules";

interface PreviewProps {
  profile?: ProfileProps | null;
  broker?: BrokerProps | null;
  isLoading?: boolean;
}

const Preview: React.FC<PreviewProps> = ({ profile, broker, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <LoadingPage rows={12}/>
      ) : (
        <>
          <div className="flex flex-col w-full px-10 pt-12 relative">
            <div className="bg-jll-surface-base-secondary-subdued absolute top-0 left-0 right-0 h-[320px] z-0" />
            <div className="grid grid-cols-12 mb-9 z-10">
              <LazyLoadImage
                src={broker?.photos?.[0] || undefined}
                className="rounded-md col-span-3"
              />
              <div />
              <div className="col-span-8">
                <div className="mb-[72px] flex flex-row justify-between">
                  <div>
                    <h3 className="text-[32px] mb-8">
                      {[broker?.first_name, broker?.last_name].join(" ")}
                    </h3>
                    <p className="text-xl text-jll-text-base-subdued">
                      {broker?.job_title || ""}
                    </p>
                  </div>
                </div>
                <div className="mb-6">
                  <p className="text-xl mb-4">Expertise</p>
                  <p>{profile?.expertise}</p>
                </div>

                <div className="grid grid-cols-3 py-[72px] gap-10 border-b border-b-jll-stroke-default-1">
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">
                      License number
                    </p>
                    <p>{profile?.license}</p>
                  </div>
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">Type</p>
                    <p>Broker</p>
                  </div>
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">Location</p>
                    <p>Broker</p>
                  </div>
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">
                      Office phone
                    </p>
                    <p>Broker</p>
                  </div>
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">Company</p>
                    <p>Broker</p>
                  </div>
                  <div className="col-span-1 text-sm">
                    <p className="text-jll-text-base-subdued mb-1">Address</p>
                    <p>Broker</p>
                  </div>
                </div>

                <div className="text-jll-text-base-subdued py-[72px]">
                  <p className="text-xl mb-4 text-jll-text-base-default">Bio</p>
                  {profile?.bio && (
                    <div
                      className="mb-12"
                      dangerouslySetInnerHTML={{
                        __html: profile?.bio?.includes(`""`)
                          ? JSON.parse(profile?.bio)
                          : profile?.bio,
                      }}
                    />
                  )}
                  {profile?.recognition && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Current responsibilities
                      </h4>
                      <p>{profile?.recognition}</p>
                    </div>
                  )}
                  {profile?.education && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Education
                      </h4>
                      <p>{profile?.education}</p>
                    </div>
                  )}
                  {profile?.affiliations && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Affiliations
                      </h4>
                      <p>{profile?.affiliations}</p>
                    </div>
                  )}

                  {profile?.experience && (
                    <div className="mb-12">
                      <h4 className="text-xl mb-4 text-jll-text-base-default">
                        Experience
                      </h4>
                      <p>{profile?.experience}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-t border-t-jll-light-stroke-default w-full mb-9" />
            <div className="grid grid-cols-12 mb-20">
              <div className="col-span-3">
                <p className="text-xl">Get in touch</p>
              </div>
              <div />
              <div className="col-span-7">
                <p className="mb-6">{`${[
                  profile?.cities?.[0]?.name,
                  profile?.cities?.[0]?.state,
                ]?.join(", ")}  |  ${profile?.country?.name}`}</p>
                <p className="mb-6">{broker?.telephone || ""}</p>
                <p className="mb-6">{broker?.email || ""}</p>
                <p className="mb-6">License # IN - {profile?.license || ""}</p>
                {!!broker?.linkedin?.length && (
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      window.open(broker?.linkedin, "_blank");
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <img src={LinkedinIcon} className="mr-2" />
                      Linkedin
                    </div>
                  </SolButton>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Preview;
