import cn from "classnames";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const USER_TABS = [
  { label: "Listing Admin", value: "admin" },
  { label: "Data Hub", value: "hub" },
  { label: "Date Report", value: "report" },
  { label: "Leadership", value: "leadership" },
];

const Leftbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | null>("industry");

  useEffect(() => {
    if (searchParams?.get("tab")) {
      setActiveTab(searchParams?.get("tab"));
    }
  }, [searchParams])

  return (
    <div className="min-w-[280px] w-[280px] h-full relative bg-jll-surface-base-secondary overflow-y-auto">
      <div className="flex flex-row items-center justify-between px-10 mt-6">
        <p className="text-jll-text-base-subdued">
          Curabitur a ipsum eget dolor malesuada tristique
        </p>
      </div>

      <div className="py-10 space-y-2 w-full">
        {USER_TABS.map((tab, idx) => (
          <div
            key={idx}
            className={cn("text-jll-text-base-subdued pl-10 pr-6 py-3 cursor-pointer flex flex-row justify-between items-center", {
              "!text-jll-text-base-default border-r border-r-jll-text-base-default": activeTab === tab.value
            })}
            onClick={()=> {
              searchParams.set("tab", tab.value);
              setSearchParams(searchParams);
            }}
          >
            <span>{tab.label}</span>
            <span className="rounded-full border border-jll-stroke-default-1 bg-white py-1 px-2 text-sm text-jll-text-base-default">618</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leftbar;
