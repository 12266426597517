import { LazyLoadImage } from "react-lazy-load-image-component";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { Facets } from "ui-molecules";
import { Link, useNavigate } from "react-router-dom";

interface ListLeftSideProps {
  facets?: any;
}

function ListLeftSide<T extends object>({ facets }: ListLeftSideProps) {
  const navigate = useNavigate();
  return (
    <div className="min-w-[280px] w-[280px] h-full relative bg-jll-surface-base-secondary overflow-y-auto">
      <div className="flex flex-row items-center justify-between px-10 py-6 border-b border-b-jll-stroke-default-1">
        <span>Filters</span>
        <SolButton
          variant="secondary"
          size="small"
          onSol-click={() => navigate("/profile")}
        >
          <span>Clear all</span>
          <SolIcon icon="close" />
        </SolButton>
      </div>

      <div className="px-10 py-8 space-y-8 w-full">
        <Facets facets={facets} />
      </div>
    </div>
  );
}

export default ListLeftSide;
