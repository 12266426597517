import { LayoutAuthHome } from "ui-organisms";
import Leftbar from "./Leftbar";
import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { getDecimalFormating } from "utils";
import { SearchOutlined } from "@material-ui/icons";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "ui-atoms";
import { useApiCall } from "hooks";
import { getCountryAPI, getOfficeAPI } from "services";
import { SEARCH_RESULT_LIMIT } from "constant";

const OfficeHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [getOffice] = useApiCall(getOfficeAPI);
  const [activeTab, setActiveTab] = useState<string | null>("country");
  const [data, setData] = useState<any[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    loadData()
  }, [paramFilters])

  const loadData = () => {
    getOffice({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      ...paramFilters,
    }).then((res: any) => {
      if (!res) return;
      setData(res?.docs);
      setPage(res?.page);
      setTotal(res?.total);
      setSortFields(res?.sort_fields);
    })
    .catch(() => {
      setData([]);
      setTotal(0);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  return (
    <>
      <LayoutAuthHome>
        <Leftbar />
        <div className="w-full h-full relative">
          {/* Filter */}
          <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between">
            <div className="flex flex-row items-center space-x-3">
              <SolDropdown
                placeholder="Industry"
                size="small"
                className="!mb-0"
                label="Industry"
              />
              <SolDropdown placeholder="Status" size="small" label="Status" />
              <div className="mt-5">
                <SolButton
                  variant="tertiary"
                  className="whitespace-nowrap text-jll-text-base-subdued"
                >
                  Clear filters
                </SolButton>
              </div>
            </div>
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[280px]"
                placeholder="Search by name, country, city..."
                label="Search"
              />
            </div>
          </div>

          <div className="flex flex-col mx-10 relative">
            <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
              <span>{`${getDecimalFormating(total)} result${
                total > 1 ? "s" : ""
              }`}</span>
            </div>
          </div>

          <div className="absolute top-[193px] left-0 right-0 bottom-0 px-10 overflow-y-auto"></div>
        </div>
      </LayoutAuthHome>
    </>
  );
};

export default OfficeHome;
