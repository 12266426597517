import { useEffect, useState } from "react";
import { Switch as ReactSwitch } from "@headlessui/react";
import cn from "classnames";

// @ts-ignore
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface ISwitch {
  enabled: boolean;
  onChange: any;
  labelClassName?: string;
  label?: string;
  className?: string;
  optional?: boolean;
  title?: string;
  switchClassName?: string;
  label_1?: string;
  label_2?: string;
}

const Switch: React.FC<ISwitch> = ({
  enabled = false,
  onChange,
  labelClassName,
  label,
  className,
  optional,
  title,
  switchClassName,
  label_1,
  label_2,
}) => {
  const [innerEnabled, setInnerEnabled] = useState(enabled);

  useEffect(() => {
    if (innerEnabled !== enabled) setInnerEnabled(enabled);
  }, [enabled]);

  const handleChange = (flag: boolean) => {
    onChange(flag);
    setInnerEnabled(flag);
  };

  return (
    <div className={cn("relative mb-6", className)}>
      {(label || optional) && (
        <div className="flex flex-row items-center">
          {label ? (
            <label
              className={cn(
                "mr-2 text-jll-text-base-subdued-2",
                labelClassName
              )}
            >
              {label}
            </label>
          ) : (
            <span></span>
          )}
        </div>
      )}
      <div
        className={cn("flex items-center", switchClassName, {
          "mt-2": !!label,
        })}
      >
        {!!label_1 && (
          <span
            className={cn("text-base mr-2", {
              "text-jll-text-base-subdued": innerEnabled,
              "text-black": !innerEnabled,
            })}
          >
            {label_1}
          </span>
        )}
        <ReactSwitch.Group>
          <ReactSwitch.Label passive>{title}</ReactSwitch.Label>
          <ReactSwitch
            checked={innerEnabled}
            onChange={handleChange}
            className={classNames(
              innerEnabled
                ? "bg-jll-surface-accent-default"
                : "bg-jll-surface-interaction-idle",
              "relative items-center inline-flex mt-0.5 h-6 w-12 p-px flex-shrink-0 cursor-pointer rounded-full border border-jll-stroke-default-1 transition-colors duration-200 ease-in-out focus:outline-none"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={classNames(
                innerEnabled ? "translate-x-[25px]" : "translate-x-[1px]",
                "pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </ReactSwitch>
        </ReactSwitch.Group>
        {!!label_2 && (
          <span
            className={cn("text-base ml-2", {
              "text-jll-text-base-subdued": !innerEnabled,
              "text-black": innerEnabled,
            })}
          >
            {label_2}
          </span>
        )}
      </div>
    </div>
  );
};

export default Switch;
