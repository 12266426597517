import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { ProfileProps } from "types";
import { convertFromHTML, convertToHTML } from "draft-convert";
import * as Yup from "yup";

interface MainBioProps {
  profile: ProfileProps | null;
  setProfile?: any;
}

const INITIAL_VALUES = {
  bio: EditorState.createEmpty(),
};

const validationSchema = Yup.object().shape({
  bio: Yup.string().required("This field is required"),
});

const MainBio: React.FC<MainBioProps> = ({ profile, setProfile }) => {
  const [searchParams] = useSearchParams();
  const [patchProfile] = useApiCall(patchProfileAPI);

  useEffect(() => {
    if (
      !profile ||
      profile?.country?.id?.toString() !== searchParams?.get("tab")
    )
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "bio") {
        if (!profile?.bio) {
          formValues = {
            ...formValues,
            [key]: EditorState.createEmpty(),
          };
          return;
        }
        const rawContentState = JSON.parse(profile?.bio);
        const contentState: any = convertFromHTML(rawContentState);
        formValues = {
          ...formValues,
          [key]: EditorState.createWithContent(contentState),
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] || "",
      };
    });
    setValues({
      ...formValues,
    });
  }, [profile]);

  const { handleBlur, setFieldValue, values, errors, touched, setValues } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

    const handleUpdate = (key: string, value: any) => {
        try {
          if (key === "bio") {
            const contentState = values?.bio?.getCurrentContent();
            const rawContentState = convertToHTML(contentState);
            const contentString = JSON.stringify(rawContentState);
            value = contentString;
          }
          patchProfile({
            pk: profile?.id,
            [key]: value,
          }).then((res: any) => {
            if (!res) return;
            setProfile({
              ...profile,
              [key]: value,
            });
          });
        } catch (err) {}
      };

  return (
    <div className="mb-12">
      <p className="text-jll-text-base-subdued mb-3">
        Current responsibilities
      </p>
      <div className="border border-jll-light-stroke-default rounded">
        <Editor
          editorState={values?.bio as any}
          toolbarClassName="mx-5 mt-5 border border-jll-light-stroke-default"
          wrapperClassName="border-none"
          editorClassName="px-5"
          onEditorStateChange={(e) => setFieldValue("bio", e)}
          onBlur={(e) => {
            handleBlur(e);
            handleUpdate("bio", values.bio);
          }}
        />

        {/* <div className="px-5 py-8">
        <SolButton variant="outlined">
          <div className="flex flex-row items-center">
            Use JLL GPT
            <img src={GPTIcon} className="ml-2" />
          </div>
        </SolButton>
      </div> */}
      </div>
    </div>
  );
};

export default MainBio;
