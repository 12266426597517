import cn from "classnames";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const LOCATION_TABS = [
  { label: "Country", value: "country" },
  { label: "Language", value: "language" },
  { label: "State", value: "state" },
  { label: "City", value: "city" },
  { label: "Market", value: "market" },
];

const Leftbar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | null>("country");

  useEffect(() => {
    if (searchParams?.get("tab")) {
      setActiveTab(searchParams?.get("tab"));
    }
  }, [searchParams])

  return (
    <div className="min-w-[300px] h-full relative bg-jll-surface-base-secondary overflow-y-auto">
      <div className="flex flex-row items-center justify-between px-10 mt-6">
        <p className="text-jll-text-base-subdued">
          Curabitur a ipsum eget dolor malesuada tristique
        </p>
      </div>

      <div className="py-10 space-y-2 w-full">
        {LOCATION_TABS.map((tab, idx) => (
          <div
            key={idx}
            className={cn("text-jll-text-base-subdued pl-10 pr-6 py-3 hover:cursor-pointer", {
              "!text-jll-text-base-default border-r border-r-jll-text-base-default": activeTab === tab.value
            })}
            onClick={()=> {
              searchParams.set("tab", tab.value);
              setSearchParams(searchParams);
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leftbar;
